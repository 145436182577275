import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import log from '../client-requests/logger';

import locationsData from '../locations/all.json';

interface Location {
  id: string;
  title: string;
  city: string;
  country: string;
  summary: string;
  description: string;
  icon: string;
  highlights: string[];
}

const LocationPage = () => {
  const {country, city} = useParams<{country: string; city: string}>();
  const [location, setLocation] = useState<Location>(null);

  useEffect(() => {
    const fetchLocation = async () => {
      if (country && city) {
        const location: Location = locationsData.locations.find(
          (location: Location) => location.country.toLowerCase() === country.toLowerCase() && location.city.toLowerCase() === city.toLowerCase(),
        );

        setLocation(location);
      }
    };

    log({level: 'info', message: 'User landed on location page', data: {country, city}});

    fetchLocation();
  }, [country, city]);

  if (!location) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>{location.title}</h1>
      <p>{location.description}</p>
    </div>
  );
};

export default LocationPage;
