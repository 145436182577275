import React, {useState, useEffect} from 'react';
import {useJobs} from '../context/jobs';
// import { format } from 'date-fns';
import parse from 'html-react-parser';
import '../styles/jobs.scss';

const removeBrTags = html => {
  return parse(html, {
    replace: domNode => {
      // If the tag is <br>, we simply skip it by returning null
      if (domNode.name === 'br') {
        console.log('log :: huh');
        return null;
      }
      // Continue parsing other nodes
      return domNode;
    },
  });
};

const JobsPage = () => {
  const jobs = useJobs(); // Get jobs from context

  if (!jobs && jobs.length) {
    return <div>Loading jobs...</div>;
  }

  return (
    <section className='jobs'>
      <h1>Remote Job Listings</h1>
      <ul>
        {jobs.map((job, index) => (
          <div key={index}>
            <li>
              <a href={job.link} target='_blank' rel='noopener noreferrer'>
                {job.title}
              </a>
              <p className='job-meta'>
                <span>Category: {job.category}</span> | <span>Type: {job.type}</span> | <span>{job.pubDate}</span>
              </p>
              <div className='job-description'>
                {parse(job.description)} {/* Parse the sanitized job description */}
              </div>
              <p className='job-apply'>
                <strong>To apply:</strong>{' '}
                <a href={job.link} target='_blank' rel='noopener noreferrer'>
                  {job.link}
                </a>
              </p>
            </li>
            <hr />
          </div>
        ))}
      </ul>
    </section>
  );
};

export default JobsPage;
