import React, {createContext, useContext} from 'react';

// Create the JobsContext
const JobsContext = createContext([]);

// Custom hook to use the JobsContext
export const useJobs = () => useContext(JobsContext);

// Provider component to wrap the page where jobs data is needed
export const JobsProvider = ({jobs = [], children}) => {
  return <JobsContext.Provider value={jobs}>{children}</JobsContext.Provider>;
};
