import React, {FC} from 'react';
import {Link} from 'react-router-dom';

interface LocationTileProps {
  image: string;
  title: string;
  highlights: string[]; // Now expecting an array of strings
  key: string;
  country: string;
  city: string;
}

const LocationTile: FC<LocationTileProps> = ({image, title, highlights, country, city}) => {
  const url = `/location/${country.toLowerCase()}/${city.toLowerCase()}`;

  return (
    <Link to={url} className='location-tile'>
      <img src={image} alt={title} className='icon' />
      <h3>{title}</h3>
      <ul>
        {highlights.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </Link>
  );
};

export default LocationTile;
