// Pages
import Health from './pages/health';
import HomePage from './pages/home';
import Terms from './pages/terms';
import FAQS from './pages/faqs';
import AboutUs from './pages/about-us';
import Contact from './pages/contact';
import Location from './pages/location';
import NotFound from './pages/not-found';
import Jobs from './pages/jobs';
import {PAGE_ROUTES} from './constants';

import seo from './configs/seo.json';

const routes = [
  {path: `/${PAGE_ROUTES.HOME}`, component: HomePage, seo: seo['home']},
  {path: '/location/:country/:city', component: Location},
  {path: '/health', component: Health},
  {path: '/terms', component: Terms},
  {path: '/faqs', component: FAQS},
  {path: '/about-us', component: AboutUs},
  {path: '/contact', component: Contact},
  {path: `/${PAGE_ROUTES.REMOTE_JOBS}`, component: Jobs},
  {path: '/', component: HomePage, seo: seo['home']},
  // This has to be last since the routes are registered in order
  {path: `${PAGE_ROUTES.WILDCARD}`, component: NotFound, seo: seo['404']},
];

export default routes;
