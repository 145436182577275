import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {AssetsBasePathContext} from '../components/assetBasePathProvider';
import {PAGE_ROUTES} from '../constants';

const Navigation = () => {
  const assetBasePath = useContext(AssetsBasePathContext);

  return (
    <nav className='main-navigation' itemScope itemType='http://schema.org/SiteNavigationElement'>
      <ul className='items'>
        <div className='logo-container'>
          {<img className='logo' src={`${assetBasePath}/assets/itsadigitallifelogo.webp`} alt='Logo' itemProp='url' />}
        </div>
        <li itemProp='name' className='item'>
          <Link to={`/${PAGE_ROUTES.HOME}`} itemProp='url'>
            Home
          </Link>
        </li>
        <li itemProp='name' className='item'>
          <Link to={`/${PAGE_ROUTES.REMOTE_JOBS}`} itemProp='url'>
            Remote jobs
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
